import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';

// services
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { SettingsService } from '../../../services/settings.service';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';

// models
import { Supplier } from '../../../models/supplier';
import { SupplierService } from '../../../services/supplier.service';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app'
import { finalize } from 'rxjs/operators';
import { DriverService, MenuService, ProductService } from '../../../services';
import { AuthService } from '../../../services/auth.service';
import { CategoryComponent } from '../../categories/category.component';


@Component({
  selector: 'app-add-user',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './add-user.component.html',
  styles: [`
    :host nb-tab {
      padding: 1.25rem;
    }
  `],
})
export class AddUserComponent implements OnInit {

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask
  uploadProgress: Observable<number>;
  downloadURL: Observable<any>;
  pictureUrl: string;
  uploaded: boolean = false;


  // define properties and types
  driver: any = {
    name: '',
    description: '',
    email: '',
    phone: '',
    password: '',
  }

  @ViewChild('driverForm', { static: false }) DriverForm: any;


  supplier: Supplier = {
    Name: '',
    Description: '',
    Email: '',
    Phone: '',
    Password: '',
    Country: '',
    Category: '',
  }
  public location: Location;

  @ViewChild('supplierForm', { static: false }) Supplierform: any;

  @ViewChild('countrySelect', { static: false }) countrySelect;

  userDocs = {}

  countries: any[];
  cars: any[];
  categories: any[];

  constructor(
    private flashMessage: FlashMessagesService,
    private supplierService: SupplierService,
    private driverService: DriverService,
    private router: Router,
    private settingsService: SettingsService,
    private afStorage: AngularFireStorage,
    private authService: AuthService,
    private menuService: MenuService,
    private productService: ProductService
  ) { }

  ngOnInit() {
    this.countries = this.menuService.listCountries();
    this.driverService.listAllCars().subscribe(cars => {
      this.cars = cars;
    });
    this.productService.getCategories().subscribe(categories =>{
      this.categories= categories
    });
    
  }

  onSubmit({ value, valid }: { value: any, valid: boolean }, isDriver: boolean) {
    value.Status = "pending";
    

    if (!isDriver) {
      value.LocationName = this.location.name;
      value.LatLng = new firebase.firestore.GeoPoint(this.location.lat, this.location.lng);
    }
    
    var userObject = {...value, ...this.userDocs}   
    
    console.log(userObject);


    if (!valid) {
      // display an error
      this.flashMessage.show('Please fill out the form correctly', {
        cssClass: 'alert-danger', timeout: 4000
      });
      this.scrollToTop();
    }else if(this.countProperties(userObject)<7){
      this.flashMessage.show('Please attach all documents', {
        cssClass: 'alert-danger', timeout: 4000
      });
      this.scrollToTop();

    }
     else {
      if (isDriver) {
        this.driverService.newDriver(userObject);
      } else {
        this.supplierService.newSupplier(userObject);
      }
      // add new supplier

      // show message
      this.flashMessage.show('Sucessful registration', {
        cssClass: 'alert-success', timeout: 4000
      });
      // redirect to dash or /
      
      this.authService.logout();
      this.router.navigate(['/success']);
      
    }
  }

  logLocationChange(location) {
    console.log(location);
    this.location = location;
  }

  upload(event, route: string) {
    const file = event.target.files[0];
    const filePath = `${route}/${this.randomString()}.jpg`;
    this.ref = this.afStorage.ref(filePath);
    this.task = this.ref.put(file);

    this.uploadProgress = this.task.percentageChanges();
    // get notified when the download URL is available
    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = this.ref.getDownloadURL();
        this.downloadURL.subscribe((data) => {
          this.pictureUrl = data;
          this.userDocs[route] = this.pictureUrl;

          this.flashMessage.show(`${route} successfully uploaded!`, {
            cssClass: 'alert-success', timeout: 4000
          });
          this.scrollToTop();

        });
      })
    ).subscribe();

    this.uploaded = true;
  }

  randomString() {
    var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = '';
    for (var i = 10; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  countProperties(obj) {
    var count = 0;

    for(var prop in obj) {
        if(obj.hasOwnProperty(prop))
            ++count;
    }

    return count;
}

}

export class Location {
  lat: number;
  lng: number;
  name?: string;
  zoom?: number;
  icon?: string;
}
