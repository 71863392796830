// angular
import { Component, OnInit, ViewChild } from '@angular/core';
// services
import { SupplierService } from '../../../services/supplier.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
// models
import { Supplier } from '../../../models/supplier';
import { ProductService } from '../../../services';
import { Product } from '../../../models/product';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['./supplier-details.component.css']
})
export class SupplierDetailsComponent implements OnInit {

 // define property types
 id: string;
 supplier: Supplier;
 products: Product[];

 @ViewChild(DatatableComponent) table: DatatableComponent;
  tempArr: any[];
  
  ColumnMode = ColumnMode;
  columns = [
    { prop: 'Name', name: 'Name' },
    { name: 'Price', prop: 'Price' },
    { name: 'Quantity', prop: 'Quantity' },
    { name: 'Uom', prop: 'Uom' }
  ];

  constructor(
    private supplierService: SupplierService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessage: FlashMessagesService,
    private productService: ProductService
  ) { }

  ngOnInit() {
    // get id from url 
    this.id = this.route.snapshot.params['id'];
    // get supplier
    this.supplierService.getSupplier(this.id).subscribe(supplier => {      
      this.supplier = supplier;
    });
    this.productService.getProducts().subscribe(products => {
      this.products = products;


      this.products.forEach(product => {
        if (this.id == product.OfferedBy) {
          return product;
        };
      });

      this.tempArr = [...products];
    });
  }

  onDeleteClick() {
    // create delete confirmation
    if (confirm('Are you sure?')) {
      // call service.method
      this.supplierService.deleteSupplier(this.supplier);
      // offer response/feedback
      this.flashMessage.show('Supplier removed', {
        cssClass: 'alert-success', timeout: 4000
      });
      // redirect
      this.router.navigate(['/summary']);
    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempArr.filter(function (d) {
      return d.Name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.products = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  selected = [];
  SelectionType = SelectionType;

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
    this.router.navigate([`/product/ref/${this.selected[0].id}`])
  }

}
