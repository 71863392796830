import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { FlashMessagesService } from 'angular2-flash-messages';
import { DriverService } from '../../services/admin/driver.service';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css']
})
export class DriversComponent implements OnInit {

  // define properties
  drivers: any[];
  outstandingTotal: number;

  ColumnMode = ColumnMode;
  columns = [
    { prop: 'FirstName', name: 'First Name' },
    { prop: 'LastName', name: 'Surname' },
    { name: 'Email', prop: 'Email' },
    { name: 'Status', prop: 'Status' },
    { name: 'Car Type', prop: 'CarType' },
    { name: 'Country', prop: 'Country' }];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  tempArr: any[];

  // inject dependency services in the constructor
  constructor(
    private driverService: DriverService,
    private flashMessage: FlashMessagesService,
    private dialogService: NbDialogService,
    private router: Router
  ) { }

  ngOnInit() {
    this.driverService.getDrivers().subscribe(drivers => {
      this.drivers = drivers;
      console.log(drivers)
      this.tempArr = [...drivers];
    });
  }

  updateDriver(driver: any, status: string) {
    driver.Status = status;
    this.driverService.updateDriver(driver)

    this.flashMessage.show('Driver status updated!', {
      cssClass: 'alert-success', timeout: 4000
    });

  }

  updateFilter(event, filter) {
    // console.log(event, filter);
    var temp;

    if (event.target.localName == "button") {
      temp = this.tempArr.filter(function (d) {
        // console.log(d);     
        return d.Status.toLowerCase().indexOf(filter) !== -1 || !filter;
      });
    } else {
      const val = event.target.value.toLowerCase();

      // filter our data
      temp = this.tempArr.filter(function (d) {
        // console.log(d);     
        return d[filter].toLowerCase().indexOf(val) !== -1 || !val;
      });

    }

    // update the rows
    this.drivers = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  selected = [];
  SelectionType = SelectionType;

  onSelect({ selected }, dialog) {
    console.log(selected[0]);
    this.dialogService.open(dialog, { context: selected[0], dialogClass: 'float-right' });
  }

  routeNav(route) {
    this.router.navigate([`/drivers/driver/${route}`])
  }

}
