// angular
import { Component, OnInit } from '@angular/core';
// services
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
// models
import { Product } from '../../../models/product';
import { SupplierService } from '../../../services';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {

  // define property types
  id: string;
  product: Product;
  supplier;
  objectKeys = Object.keys;

  constructor(
    private productService: ProductService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessage: FlashMessagesService,
    private supplierService: SupplierService,
  ) { }

  ngOnInit() {
    // get id from url 
    this.id = this.route.snapshot.params['id'];
    // get product
    this.productService.getProduct(this.id).subscribe(product => {
      this.product = product;

      this.supplierService.getSupplier(this.product.OfferedBy).subscribe(supplier => {
        this.supplier = supplier;
      });

      console.log('Product: ', this.product);
    });    
  }

  onDeleteClick() {
    // create delete confirmation
    if (confirm('Are you sure?')) {
      // call service.method
      this.productService.deleteProduct(this.product, this.id);
      // offer response/feedback
      this.flashMessage.show('Product removed', {
        cssClass: 'alert-success', timeout: 4000
      });
      // redirect
      this.router.navigate(['/summary']);
    }
  }

}
