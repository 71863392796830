import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { Product } from '../../../models/product';
import { Supplier } from '../../../models/supplier';
import { ProductService, SupplierService } from '../../../services';

@Component({
  selector: 'app-category-products',
  templateUrl: './category-products.component.html',
  styleUrls: ['./category-products.component.css']
})
export class CategoryProductsComponent implements OnInit {

  // define properties
  products: Product[];
  category: any;
  suppliers: Supplier[];

  ColumnMode = ColumnMode;
  columns = [
    { prop: 'Name', name: 'Name' },
    { name: 'Price', prop: 'Price' },
    { name: 'Quantity', prop: 'Quantity' },
    { name: 'Supplier', prop: 'OfferedBy' },
    { name: 'Uom', prop: 'Uom' }];

  tempArr = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;


  // inject dependency services in the constructor
  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private supplierService: SupplierService,
    private router: Router

  ) { }

  ngOnInit() {
    this.supplierService.getSuppliers().subscribe(suppliers => {
      this.suppliers = suppliers;
    })
    this.category = this.route.snapshot.paramMap.get('id');
    this.productService.getCategoryProducts("Onions").subscribe(products => {
      console.log(products);
      this.products = products.filter(product => {
        return product.Category == this.category;
      });

      this.products.forEach(product => {

        this.suppliers.filter(supplier => {
          if (supplier.ID == product.OfferedBy) {
            product.OfferedBy = supplier.FirstName;
          };
        });
      });

      this.tempArr = [...this.products];
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempArr.filter(function (d) {
      return d.Name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.products = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  selected = [];
  SelectionType = SelectionType;

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
    this.router.navigate([`/product/ref/${this.selected[0].id}`])
  }

}
