import { Component, OnInit, TemplateRef } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ActivatedRoute } from '@angular/router';
import { DriverService } from '../../services';

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
})
export class DriverComponent implements OnInit {
  private gridApi;
  private gridColumnApi;

  pageContent: string;
  content = {
    confirm: false,
    display: false,
  };

  columnDefs = [
    { name: 'First Name', prop: 'FirstName', sortable: true, filter: true, },
    { name: 'Second Name', prop: 'LastName', sortable: true, filter: true },
    { name: 'Phone', prop: 'Phone', sortable: true, filter: true },
    { name: 'Email', prop: 'Email', sortable: true, filter: true },
    { name: 'CarRegNo', prop: 'CarRegNo', sortable: true, filter: true },
    { name: 'Region', prop: 'Region', sortable: true, filter: true },
    { name: 'Status', prop: 'Status', sortable: true, filter: true },
  ];

  driversData: any[];

  constructor(
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
    private driverService: DriverService
  ) { }

  ngOnInit() {
    this.pageContent = this.route.snapshot.paramMap.get('action');
    if (this.pageContent == '' || this.pageContent == null) {
      this.content.display = true;
      this.loadDataFromService();
    }
    if (this.pageContent == 'verify') {
      this.content.confirm = true;
      this.loadDataFromService();
    }
  }


  displayAlert(dialog: TemplateRef<any>, event: any) {
    var selectedRows = this.gridApi.getSelectedRows();
    this.dialogService.open(dialog, { context: selectedRows[0] });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  loadDataFromService() {
    const driversObservable = this.driverService.listAllDrivers();

    driversObservable.subscribe((drivers) => {
      // console.log(drivers)
      this.driversData = drivers
      localStorage.setItem("drivers", JSON.stringify(drivers));
    }, (error) => {
      console.log("error")
    })
  }

  processData(userId: string, status: string) {
    console.log(userId, status);
  }

}
