import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../../../services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  menuItems: any[];


  constructor(private _router: Router, public menuService: MenuService) {
    this.menuItems = this.menuService.listMenuItems();
    
  }

  ngOnInit() {
  }

}


    