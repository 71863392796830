export * from './comments/comments.component';
export * from './driver/driver.component';
export * from './fare/fare.component';
export * from './income/income.component';
export * from './notification/notification.component';
export * from './passenger/passenger.component';
export * from './payment/payment.component';
export * from './products/product.component';
export * from './profile/profile.component';
export * from './reports/reports.component';
export * from './suppliers/suppliers.component';
export * from './trip/trip.component';
export * from './wallet/wallet.component';
export * from './trip/active/trip-active.component'
export * from './trip/paid/trip-paid.component';
export * from './trip/completed/trip-complete.component';
export * from './categories/category.component';
export * from './categories/categoryProducts/category-products.component';
export * from './drivers/drivers.component';
export * from './drivers/add-driver/add-driver.component';
export * from './drivers/edit-driver/edit-driver.component';
export * from './drivers/drivers-details/driver-details.component';
export * from './orders/orders.component';
export * from './fare/add-car/add-car.component';
export * from './suppliers/supplier-stats/suppliers-stats.component';