import { Component, OnInit, TemplateRef } from '@angular/core';
import { ProductService } from '../../services';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app'
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  // define properties
  categories: any[];

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask
  uploadProgress: Observable<number>;
  downloadURL: Observable<any>;
  pictureUrl: string;
  uploaded: boolean = false;

  // inject dependency services in the constructor
  constructor(
    private productService: ProductService,
    private afStorage: AngularFireStorage,
    private dialogService: NbDialogService,
    ) { }

  ngOnInit() {
    this.productService.getCategories().subscribe(categories => {
      this.categories = categories;
    });
  } 

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }

  upload(event) {
    const file = event.target.files[0];
    const filePath = "categories/" + this.randomString() + ".jpg";
    this.ref = this.afStorage.ref(filePath);
    this.task = this.ref.put(file);

    this.uploadProgress = this.task.percentageChanges();
    // get notified when the download URL is available
    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = this.ref.getDownloadURL();
        this.downloadURL.subscribe((data) => {
          this.pictureUrl = data;
        });
      })
    ).subscribe();

    this.uploaded = true;
  }

  randomString() {
    var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = '';
    for (var i = 10; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  addCategory(categoryLabel) {
    var categoryData = {
      id: categoryLabel,
      Image : this.pictureUrl
    }

    console.log("Category data", categoryData)

    this.productService.addCategory(categoryData);
  }
}
