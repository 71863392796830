// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // should have a separate db instance for dev other than prod
  firebase: {
    apiKey: "AIzaSyAHI9lZLS4F7fP5iToIKFGp0AYxgHAvWgY",
    authDomain: "glyyde-monster.firebaseapp.com",
    databaseURL: "https://glyyde-monster.firebaseio.com",
    projectId: "glyyde-monster",
    storageBucket: "glyyde-monster.appspot.com",
    messagingSenderId: "53446847293",
    appId: "1:53446847293:web:de71442066744951a2b354",
    measurementId: "G-42PLMJK8E9"
  }
};
