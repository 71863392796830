// angular
import { Component, OnInit, ViewChild } from '@angular/core';
// services
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { SettingsService } from '../../../services/settings.service';

// models
import { Product } from '../../../models/product';
import { ProductService } from '../../../services/product.service';


@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {

  // define properties and types
  product: Product = {
    Name: '',
    Category: '',
    Brand: '',
    Quantity: 1,
    Price: null,
    Uom: "mm",    
  }

  @ViewChild('productForm', {static: false}) form: any;

  constructor(
    private flashMessage: FlashMessagesService,
    private productService: ProductService,
    private router: Router,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
  }

  onSubmit({value, valid}: {value: Product, valid: boolean }) {
    console.log(value, valid);

    if (!valid) {
      // display an error
      this.flashMessage.show('Please fill out the form correctly', {
        cssClass: 'alert-danger', timeout: 4000
      });
      this.scrollToTop();
    } else {
      // add new product
      this.productService.newProduct(value);
      // show message
      this.flashMessage.show('New product added', {
        cssClass: 'alert-success', timeout: 4000
      });
      // redirect to dash or /
      this.router.navigate(['/summary']);
    }
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

}
