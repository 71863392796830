// angular
import { Component, OnInit } from '@angular/core';
// services
import { SupplierService } from '../../../services/supplier.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { SettingsService } from '../../../services/settings.service';
// models
import { Supplier } from '../../../models/supplier';


@Component({
  selector: 'app-edit-supplier',
  templateUrl: './edit-supplier.component.html',
  styleUrls: ['./edit-supplier.component.css']
})
export class EditSupplierComponent implements OnInit {

  id: string;
  supplier: Supplier = {
    Name: '',
    Description: '',
    Email: '',
    Phone: '',
  }

  constructor(
    private supplierService: SupplierService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessage: FlashMessagesService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {

    // get id from url 
    this.id = this.route.snapshot.params['id'];
    // get supplier
    this.supplierService.getSupplier(this.id).subscribe(supplier => this.supplier = supplier);
  }

  onSubmit({value, valid}: {value: Supplier, valid: boolean }) {
    // submit method
    if (!valid) {
      this.flashMessage.show('Please file out the form correctly', {
        cssClass: 'alert-danger', timeout: 4000
      });

      this.scrollToTop();
    } else {
      // add id to supplier
      value.ID = this.id
      console.log(value);
      // update supplier
      this.supplierService.updateSupplier(value);
      // add alert
      this.flashMessage.show('Supplier updated', {
        cssClass: 'alert-success', timeout: 4000
      });
      this.router.navigate(['/supplier/' + this.id]);
    }
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

}
