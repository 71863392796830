import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ActivatedRoute } from '@angular/router';
import { PassengerService } from '../../services';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-passenger',
  templateUrl: './passenger.component.html',
  styleUrls: ['./passenger.component.scss']
})
export class PassengerComponent implements OnInit {
  private gridApi;
  private gridColumnApi;

  columns = [
    { name: 'Name', prop: 'Name' },
    { name: 'Phone Number', prop: 'PhoneNumber' },
    { prop: 'Country', name: 'Country' }
  ];

  passengersData: any[];

  ColumnMode = ColumnMode.force;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  tempArr: any[];

  constructor(
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
    private passengerService: PassengerService
  ) { }

  ngOnInit() {
    this.loadDataFromService();
  }

  displayAlert(dialog: TemplateRef<any>, event: any) {
    var selectedRows = this.gridApi.getSelectedRows();
    this.dialogService.open(dialog, { context: selectedRows[0] });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  loadDataFromService() {
    const passengerObservable = this.passengerService.listAllPassengers();

    passengerObservable.subscribe((passengers) => {
      this.passengersData = passengers
      console.log(this.passengersData);
    }, (error) => {
      console.log("error")
    })
  }

  processData(userId: string, status: string) {
    console.log(userId, status);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempArr.filter(function (d) {
      return d.invoiceNumber.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.passengersData = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  selected = [];
  SelectionType = SelectionType;

  onSelect({ selected }, dialog) {
    console.log('Select Event', selected, this.selected);
    this.dialogService.open(dialog, { context: selected[0], dialogClass: 'float-right' });
  }

}
