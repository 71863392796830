import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  listMenuItems() {
    return MENUITEMS;
  }

  listCountries() {
    return COUNTRIES;
  }

  listCurrencies(){
    return CURRENCIES;
  }


}

const MENUITEMS = [
  {
    title: 'Dashboard',
    link: '/summary',
    icon: 'browser-outline'
  },  
  {
    title: 'Suppliers',
    expanded: false,    
    link: ['/suppliers'],
    icon: 'clipboard-outline',
    children: [
      {
        title: 'All Suppliers',
        link: ['/suppliers'],
        icon: 'clipboard-outline'
      },
      {
        title: 'Stats & Revenues',
        link: ['/suppliers/stats'],
        icon: 'percent-outline'
      },
      {
        title: 'All Categories',
        link: ['/categories'],
        icon: 'book-outline'
      },
      {
        title: 'Orders',
        icon: 'checkmark-square-2-outline',
        expanded: false,
        children: [
          {
            title: 'All orders',
            link: ['/orders'],
            icon: 'checkmark-square-2-outline'
          },
          {
            title: '',
            link: ['/orders/pending'],
            icon: 'close-square-outline'
          },
        ]
      },
    ]
  },  
  
  {
    title: 'Driver Management',
    icon: 'car-outline',
    expanded: false,
    children: [
      {
        title: 'All drivers',
        link: ['/drivers'],
        icon: 'car-outline'
      },
      {
        title: 'Add drivers',
        link: ['/drivers/add'],
        icon: 'car-outline'
      },
      {
        title: 'Vehicle Classes',
        icon: 'car-outline',
        children: [
          {
            title: 'ALl Classes',
            link: ['/cars'],
            icon: 'car-outline'
          },
          {
            title: 'Add new Car Type',
            link: ['/cars/add'],
            icon: 'plus-square-outline'
          },
        ]
      },
      {
        title: 'Comments',
        link: '/comments',
        icon: 'message-circle-outline'
      },
      {
        title: 'Trip Details',
        expanded: false,
        icon: 'car-outline',
        children: [
          {
            title: 'All Trips',
            link: ['/trips'],
            icon: 'car'
          },
          {
            title: 'Completed Trips',
            link: ['/trips/complete'],
            icon: 'car'
          },
          {
            title: 'Paid Trips',
            link: ['/trips/paid'],
            icon: 'car-outline'
          },
          {
            title: 'Active Trips',
            link: ['/trips/active'],
            icon: 'car-outline'
          }
        ]
      },
      {
        title: 'Drivers Accounts',
        icon: 'person-outline',
        expanded: false,
        children: [
          {
            title: 'Accounts',
            link: ['/income'],
            icon: 'credit-card-outline'
          },
        ]
      },
    ]
  },
  {
    title: 'Passenger Management',
    expanded: false,
    icon: 'person-outline',
    children: [
      {
        title: 'All Passengers',
        link: ['/passengers'],
        icon: 'person-outline'
      },
    ]
  },
  {
    title: 'Payments and Wallets',
    expanded: false,
    icon: 'credit-card-outline',
    children: [
      {
        title: 'Make payments',
        link: ['/payment'],
        icon: 'credit-card-outline'
      },
    ]
  },
  {
    title: 'Fare Management',
    expanded: false,
    icon: 'car-outline',
    children: [
      {
        title: 'Fare Settings',
        link: ['/fare'],
        icon: 'car-outline'
      },      
    ]
  },  
  {
    title: 'Profiles',
    expanded: false,
    icon: 'person-outline',
    children: [
      {
        title: 'Drivers',
        link: ['/drivers'],
        icon: 'person-outline'
      },
      {
        title: 'Passengers',
        link: ['/passengers'],
        icon: 'person-outline'
      },
    ]
  },
  {
    title: 'Logout',
    link: '/auth/logout',
    icon: 'unlock-outline'
  },
];

const COUNTRIES = [ 
  {name: 'Afghanistan', code: 'AF'}, 
  {name: 'Åland Islands', code: 'AX'}, 
  {name: 'Albania', code: 'AL'}, 
  {name: 'Algeria', code: 'DZ'}, 
  {name: 'American Samoa', code: 'AS'}, 
  {name: 'AndorrA', code: 'AD'}, 
  {name: 'Angola', code: 'AO'}, 
  {name: 'Anguilla', code: 'AI'}, 
  {name: 'Antarctica', code: 'AQ'}, 
  {name: 'Antigua and Barbuda', code: 'AG'}, 
  {name: 'Argentina', code: 'AR'}, 
  {name: 'Armenia', code: 'AM'}, 
  {name: 'Aruba', code: 'AW'}, 
  {name: 'Australia', code: 'AU'}, 
  {name: 'Austria', code: 'AT'}, 
  {name: 'Azerbaijan', code: 'AZ'}, 
  {name: 'Bahamas', code: 'BS'}, 
  {name: 'Bahrain', code: 'BH'}, 
  {name: 'Bangladesh', code: 'BD'}, 
  {name: 'Barbados', code: 'BB'}, 
  {name: 'Belarus', code: 'BY'}, 
  {name: 'Belgium', code: 'BE'}, 
  {name: 'Belize', code: 'BZ'}, 
  {name: 'Benin', code: 'BJ'}, 
  {name: 'Bermuda', code: 'BM'}, 
  {name: 'Bhutan', code: 'BT'}, 
  {name: 'Bolivia', code: 'BO'}, 
  {name: 'Bosnia and Herzegovina', code: 'BA'}, 
  {name: 'Botswana', code: 'BW'}, 
  {name: 'Bouvet Island', code: 'BV'}, 
  {name: 'Brazil', code: 'BR'}, 
  {name: 'British Indian Ocean Territory', code: 'IO'}, 
  {name: 'Brunei Darussalam', code: 'BN'}, 
  {name: 'Bulgaria', code: 'BG'}, 
  {name: 'Burkina Faso', code: 'BF'}, 
  {name: 'Burundi', code: 'BI'}, 
  {name: 'Cambodia', code: 'KH'}, 
  {name: 'Cameroon', code: 'CM'}, 
  {name: 'Canada', code: 'CA'}, 
  {name: 'Cape Verde', code: 'CV'}, 
  {name: 'Cayman Islands', code: 'KY'}, 
  {name: 'Central African Republic', code: 'CF'}, 
  {name: 'Chad', code: 'TD'}, 
  {name: 'Chile', code: 'CL'}, 
  {name: 'China', code: 'CN'}, 
  {name: 'Christmas Island', code: 'CX'}, 
  {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
  {name: 'Colombia', code: 'CO'}, 
  {name: 'Comoros', code: 'KM'}, 
  {name: 'Congo', code: 'CG'}, 
  {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
  {name: 'Cook Islands', code: 'CK'}, 
  {name: 'Costa Rica', code: 'CR'}, 
  {name: 'Cote D\'Ivoire', code: 'CI'}, 
  {name: 'Croatia', code: 'HR'}, 
  {name: 'Cuba', code: 'CU'}, 
  {name: 'Cyprus', code: 'CY'}, 
  {name: 'Czech Republic', code: 'CZ'}, 
  {name: 'Denmark', code: 'DK'}, 
  {name: 'Djibouti', code: 'DJ'}, 
  {name: 'Dominica', code: 'DM'}, 
  {name: 'Dominican Republic', code: 'DO'}, 
  {name: 'Ecuador', code: 'EC'}, 
  {name: 'Egypt', code: 'EG'}, 
  {name: 'El Salvador', code: 'SV'}, 
  {name: 'Equatorial Guinea', code: 'GQ'}, 
  {name: 'Eritrea', code: 'ER'}, 
  {name: 'Estonia', code: 'EE'}, 
  {name: 'Ethiopia', code: 'ET'}, 
  {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
  {name: 'Faroe Islands', code: 'FO'}, 
  {name: 'Fiji', code: 'FJ'}, 
  {name: 'Finland', code: 'FI'}, 
  {name: 'France', code: 'FR'}, 
  {name: 'French Guiana', code: 'GF'}, 
  {name: 'French Polynesia', code: 'PF'}, 
  {name: 'French Southern Territories', code: 'TF'}, 
  {name: 'Gabon', code: 'GA'}, 
  {name: 'Gambia', code: 'GM'}, 
  {name: 'Georgia', code: 'GE'}, 
  {name: 'Germany', code: 'DE'}, 
  {name: 'Ghana', code: 'GH'}, 
  {name: 'Gibraltar', code: 'GI'}, 
  {name: 'Greece', code: 'GR'}, 
  {name: 'Greenland', code: 'GL'}, 
  {name: 'Grenada', code: 'GD'}, 
  {name: 'Guadeloupe', code: 'GP'}, 
  {name: 'Guam', code: 'GU'}, 
  {name: 'Guatemala', code: 'GT'}, 
  {name: 'Guernsey', code: 'GG'}, 
  {name: 'Guinea', code: 'GN'}, 
  {name: 'Guinea-Bissau', code: 'GW'}, 
  {name: 'Guyana', code: 'GY'}, 
  {name: 'Haiti', code: 'HT'}, 
  {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
  {name: 'Holy See (Vatican City State)', code: 'VA'}, 
  {name: 'Honduras', code: 'HN'}, 
  {name: 'Hong Kong', code: 'HK'}, 
  {name: 'Hungary', code: 'HU'}, 
  {name: 'Iceland', code: 'IS'}, 
  {name: 'India', code: 'IN'}, 
  {name: 'Indonesia', code: 'ID'}, 
  {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
  {name: 'Iraq', code: 'IQ'}, 
  {name: 'Ireland', code: 'IE'}, 
  {name: 'Isle of Man', code: 'IM'}, 
  {name: 'Israel', code: 'IL'}, 
  {name: 'Italy', code: 'IT'}, 
  {name: 'Jamaica', code: 'JM'}, 
  {name: 'Japan', code: 'JP'}, 
  {name: 'Jersey', code: 'JE'}, 
  {name: 'Jordan', code: 'JO'}, 
  {name: 'Kazakhstan', code: 'KZ'}, 
  {name: 'Kenya', code: 'KE'}, 
  {name: 'Kiribati', code: 'KI'}, 
  {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
  {name: 'Korea, Republic of', code: 'KR'}, 
  {name: 'Kuwait', code: 'KW'}, 
  {name: 'Kyrgyzstan', code: 'KG'}, 
  {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
  {name: 'Latvia', code: 'LV'}, 
  {name: 'Lebanon', code: 'LB'}, 
  {name: 'Lesotho', code: 'LS'}, 
  {name: 'Liberia', code: 'LR'}, 
  {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
  {name: 'Liechtenstein', code: 'LI'}, 
  {name: 'Lithuania', code: 'LT'}, 
  {name: 'Luxembourg', code: 'LU'}, 
  {name: 'Macao', code: 'MO'}, 
  {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
  {name: 'Madagascar', code: 'MG'}, 
  {name: 'Malawi', code: 'MW'}, 
  {name: 'Malaysia', code: 'MY'}, 
  {name: 'Maldives', code: 'MV'}, 
  {name: 'Mali', code: 'ML'}, 
  {name: 'Malta', code: 'MT'}, 
  {name: 'Marshall Islands', code: 'MH'}, 
  {name: 'Martinique', code: 'MQ'}, 
  {name: 'Mauritania', code: 'MR'}, 
  {name: 'Mauritius', code: 'MU'}, 
  {name: 'Mayotte', code: 'YT'}, 
  {name: 'Mexico', code: 'MX'}, 
  {name: 'Micronesia, Federated States of', code: 'FM'}, 
  {name: 'Moldova, Republic of', code: 'MD'}, 
  {name: 'Monaco', code: 'MC'}, 
  {name: 'Mongolia', code: 'MN'}, 
  {name: 'Montserrat', code: 'MS'}, 
  {name: 'Morocco', code: 'MA'}, 
  {name: 'Mozambique', code: 'MZ'}, 
  {name: 'Myanmar', code: 'MM'}, 
  {name: 'Namibia', code: 'NA'}, 
  {name: 'Nauru', code: 'NR'}, 
  {name: 'Nepal', code: 'NP'}, 
  {name: 'Netherlands', code: 'NL'}, 
  {name: 'Netherlands Antilles', code: 'AN'}, 
  {name: 'New Caledonia', code: 'NC'}, 
  {name: 'New Zealand', code: 'NZ'}, 
  {name: 'Nicaragua', code: 'NI'}, 
  {name: 'Niger', code: 'NE'}, 
  {name: 'Nigeria', code: 'NG'}, 
  {name: 'Niue', code: 'NU'}, 
  {name: 'Norfolk Island', code: 'NF'}, 
  {name: 'Northern Mariana Islands', code: 'MP'}, 
  {name: 'Norway', code: 'NO'}, 
  {name: 'Oman', code: 'OM'}, 
  {name: 'Pakistan', code: 'PK'}, 
  {name: 'Palau', code: 'PW'}, 
  {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
  {name: 'Panama', code: 'PA'}, 
  {name: 'Papua New Guinea', code: 'PG'}, 
  {name: 'Paraguay', code: 'PY'}, 
  {name: 'Peru', code: 'PE'}, 
  {name: 'Philippines', code: 'PH'}, 
  {name: 'Pitcairn', code: 'PN'}, 
  {name: 'Poland', code: 'PL'}, 
  {name: 'Portugal', code: 'PT'}, 
  {name: 'Puerto Rico', code: 'PR'}, 
  {name: 'Qatar', code: 'QA'}, 
  {name: 'Reunion', code: 'RE'}, 
  {name: 'Romania', code: 'RO'}, 
  {name: 'Russian Federation', code: 'RU'}, 
  {name: 'RWANDA', code: 'RW'}, 
  {name: 'Saint Helena', code: 'SH'}, 
  {name: 'Saint Kitts and Nevis', code: 'KN'}, 
  {name: 'Saint Lucia', code: 'LC'}, 
  {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
  {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
  {name: 'Samoa', code: 'WS'}, 
  {name: 'San Marino', code: 'SM'}, 
  {name: 'Sao Tome and Principe', code: 'ST'}, 
  {name: 'Saudi Arabia', code: 'SA'}, 
  {name: 'Senegal', code: 'SN'}, 
  {name: 'Serbia and Montenegro', code: 'CS'}, 
  {name: 'Seychelles', code: 'SC'}, 
  {name: 'Sierra Leone', code: 'SL'}, 
  {name: 'Singapore', code: 'SG'}, 
  {name: 'Slovakia', code: 'SK'}, 
  {name: 'Slovenia', code: 'SI'}, 
  {name: 'Solomon Islands', code: 'SB'}, 
  {name: 'Somalia', code: 'SO'}, 
  {name: 'South Africa', code: 'ZA'}, 
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
  {name: 'Spain', code: 'ES'}, 
  {name: 'Sri Lanka', code: 'LK'}, 
  {name: 'Sudan', code: 'SD'}, 
  {name: 'Suriname', code: 'SR'}, 
  {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
  {name: 'Swaziland', code: 'SZ'}, 
  {name: 'Sweden', code: 'SE'}, 
  {name: 'Switzerland', code: 'CH'}, 
  {name: 'Syrian Arab Republic', code: 'SY'}, 
  {name: 'Taiwan, Province of China', code: 'TW'}, 
  {name: 'Tajikistan', code: 'TJ'}, 
  {name: 'Tanzania, United Republic of', code: 'TZ'}, 
  {name: 'Thailand', code: 'TH'}, 
  {name: 'Timor-Leste', code: 'TL'}, 
  {name: 'Togo', code: 'TG'}, 
  {name: 'Tokelau', code: 'TK'}, 
  {name: 'Tonga', code: 'TO'}, 
  {name: 'Trinidad and Tobago', code: 'TT'}, 
  {name: 'Tunisia', code: 'TN'}, 
  {name: 'Turkey', code: 'TR'}, 
  {name: 'Turkmenistan', code: 'TM'}, 
  {name: 'Turks and Caicos Islands', code: 'TC'}, 
  {name: 'Tuvalu', code: 'TV'}, 
  {name: 'Uganda', code: 'UG'}, 
  {name: 'Ukraine', code: 'UA'}, 
  {name: 'United Arab Emirates', code: 'AE'}, 
  {name: 'United Kingdom', code: 'GB'}, 
  {name: 'United States', code: 'US'}, 
  {name: 'United States Minor Outlying Islands', code: 'UM'}, 
  {name: 'Uruguay', code: 'UY'}, 
  {name: 'Uzbekistan', code: 'UZ'}, 
  {name: 'Vanuatu', code: 'VU'}, 
  {name: 'Venezuela', code: 'VE'}, 
  {name: 'Viet Nam', code: 'VN'}, 
  {name: 'Virgin Islands, British', code: 'VG'}, 
  {name: 'Virgin Islands, U.S.', code: 'VI'}, 
  {name: 'Wallis and Futuna', code: 'WF'}, 
  {name: 'Western Sahara', code: 'EH'}, 
  {name: 'Yemen', code: 'YE'}, 
  {name: 'Zambia', code: 'ZM'}, 
  {name: 'Zimbabwe', code: 'ZW'} 
]

const CURRENCIES = [
  {currency:"AED", name: "United Arab Emirates Dirhamname"},
  {currency:"AFN", name: "Afghan Afghaniname"},
  {currency:"ALL", name: "Albanian Lekname"},
  {currency:"AMD", name: "Armenian Dramname"},
  {currency:"ANG", name: "Netherlands Antillean Guildername"},
  {currency:"AOA", name: "Angolan Kwanzaname"},
  {currency:"ARS", name: "Argentine Pesoname"},
  {currency:"AUD", name: "Australian Dollarname"},
  {currency:"AWG", name: "Aruban Florinname"},
  {currency:"AZN", name: "Azerbaijani Manatname"},
  {currency:"BAM", name: "Bosnia-Herzegovina Convertible Markname"},
  {currency:"BBD", name: "Barbadian Dollarname"},
  {currency:"BDT", name: "Bangladeshi Takaname"},
  {currency:"BGN", name: "Bulgarian Levname"},
  {currency:"BHD", name: "Bahraini Dinarname"},
  {currency:"BIF", name: "Burundian Francname"},
  {currency:"BMD", name: "Bermudan Dollarname"},
  {currency:"BND", name: "Brunei Dollarname"},
  {currency:"BOB", name: "Bolivian Bolivianoname"},
  {currency:"BRL", name: "Brazilian Realname"},
  {currency:"BSD", name: "Bahamian Dollarname"},
  {currency:"BTC", name: "Bitcoinname"},
  {currency:"BTN", name: "Bhutanese Ngultrumname"},
  {currency:"BWP", name: "Botswanan Pulaname"},
  {currency:"BYN", name: "Belarusian Rublename"},
  {currency:"BZD", name: "Belize Dollarname"},
  {currency:"CAD", name: "Canadian Dollarname"},
  {currency:"CDF", name: "Congolese Francname"},
  {currency:"CHF", name: "Swiss Francname"},
  {currency:"CLF", name: "Chilean Unit of Account (UF)"},
  {currency:"CLP", name: "Chilean Pesoname"},
  {currency:"CNH", name: "Chinese Yuan (Offshore)"},
  {currency:"CNY", name: "Chinese Yuanname"},
  {currency:"COP", name: "Colombian Pesoname"},
  {currency:"CRC", name: "Costa Rican Colónname"},
  {currency:"CUC", name: "Cuban Convertible Pesoname"},
  {currency:"CUP", name: "Cuban Pesoname"},
  {currency:"CVE", name: "Cape Verdean Escudoname"},
  {currency:"CZK", name: "Czech Republic Korunaname"},
  {currency:"DJF", name: "Djiboutian Francname"},
  {currency:"DKK", name: "Danish Kronename"},
  {currency:"DOP", name: "Dominican Pesoname"},
  {currency:"DZD", name: "Algerian Dinarname"},
  {currency:"EGP", name: "Egyptian Poundname"},
  {currency:"ERN", name: "Eritrean Nakfaname"},
  {currency:"ETB", name: "Ethiopian Birrname"},
  {currency:"EUR", name: "Euroname"},
  {currency:"FJD", name: "Fijian Dollarname"},
  {currency:"FKP", name: "Falkland Islands Poundname"},
  {currency:"GBP", name: "British Pound Sterlingname"},
  {currency:"GEL", name: "Georgian Lariname"},
  {currency:"GGP", name: "Guernsey Poundname"},
  {currency:"GHS", name: "Ghanaian Cediname"},
  {currency:"GIP", name: "Gibraltar Poundname"},
  {currency:"GMD", name: "Gambian Dalasiname"},
  {currency:"GNF", name: "Guinean Francname"},
  {currency:"GTQ", name: "Guatemalan Quetzalname"},
  {currency:"GYD", name: "Guyanaese Dollarname"},
  {currency:"HKD", name: "Hong Kong Dollarname"},
  {currency:"HNL", name: "Honduran Lempiraname"},
  {currency:"HRK", name: "Croatian Kunaname"},
  {currency:"HTG", name: "Haitian Gourdename"},
  {currency:"HUF", name: "Hungarian Forintname"},
  {currency:"IDR", name: "Indonesian Rupiahname"},
  {currency:"ILS", name: "Israeli New Sheqelname"},
  {currency:"IMP", name: "Manx poundname"},
  {currency:"INR", name: "Indian Rupeename"},
  {currency:"IQD", name: "Iraqi Dinarname"},
  {currency:"IRR", name: "Iranian Rialname"},
  {currency:"ISK", name: "Icelandic Krónaname"},
  {currency:"JEP", name: "Jersey Poundname"},
  {currency:"JMD", name: "Jamaican Dollarname"},
  {currency:"JOD", name: "Jordanian Dinarname"},
  {currency:"JPY", name: "Japanese Yenname"},
  {currency:"KES", name: "Kenyan Shillingname"},
  {currency:"KGS", name: "Kyrgystani Somname"},
  {currency:"KHR", name: "Cambodian Rielname"},
  {currency:"KMF", name: "Comorian Francname"},
  {currency:"KPW", name: "North Korean Wonname"},
  {currency:"KRW", name: "South Korean Wonname"},
  {currency:"KWD", name: "Kuwaiti Dinarname"},
  {currency:"KYD", name: "Cayman Islands Dollarname"},
  {currency:"KZT", name: "Kazakhstani Tengename"},
  {currency:"LAK", name: "Laotian Kipname"},
  {currency:"LBP", name: "Lebanese Poundname"},
  {currency:"LKR", name: "Sri Lankan Rupeename"},
  {currency:"LRD", name: "Liberian Dollarname"},
  {currency:"LSL", name: "Lesotho Lotiname"},
  {currency:"LYD", name: "Libyan Dinarname"},
  {currency:"MAD", name: "Moroccan Dirhamname"},
  {currency:"MDL", name: "Moldovan Leuname"},
  {currency:"MGA", name: "Malagasy Ariaryname"},
  {currency:"MKD", name: "Macedonian Denarname"},
  {currency:"MMK", name: "Myanma Kyatname"},
  {currency:"MNT", name: "Mongolian Tugrikname"},
  {currency:"MOP", name: "Macanese Patacaname"},
  {currency:"MRO", name: "Mauritanian Ouguiya (pre-2018)"},
  {currency:"MRU", name: "Mauritanian Ouguiyaname"},
  {currency:"MUR", name: "Mauritian Rupeename"},
  {currency:"MVR", name: "Maldivian Rufiyaaname"},
  {currency:"MWK", name: "Malawian Kwachaname"},
  {currency:"MXN", name: "Mexican Pesoname"},
  {currency:"MYR", name: "Malaysian Ringgitname"},
  {currency:"MZN", name: "Mozambican Meticalname"},
  {currency:"NAD", name: "Namibian Dollarname"},
  {currency:"NGN", name: "Nigerian Nairaname"},
  {currency:"NIO", name: "Nicaraguan Córdobaname"},
  {currency:"NOK", name: "Norwegian Kronename"},
  {currency:"NPR", name: "Nepalese Rupeename"},
  {currency:"NZD", name: "New Zealand Dollarname"},
  {currency:"OMR", name: "Omani Rialname"},
  {currency:"PAB", name: "Panamanian Balboaname"},
  {currency:"PEN", name: "Peruvian Nuevo Solname"},
  {currency:"PGK", name: "Papua New Guinean Kinaname"},
  {currency:"PHP", name: "Philippine Pesoname"},
  {currency:"PKR", name: "Pakistani Rupeename"},
  {currency:"PLN", name: "Polish Zlotyname"},
  {currency:"PYG", name: "Paraguayan Guaraniname"},
  {currency:"QAR", name: "Qatari Rialname"},
  {currency:"RON", name: "Romanian Leuname"},
  {currency:"RSD", name: "Serbian Dinarname"},
  {currency:"RUB", name: "Russian Rublename"},
  {currency:"RWF", name: "Rwandan Francname"},
  {currency:"SAR", name: "Saudi Riyalname"},
  {currency:"SBD", name: "Solomon Islands Dollarname"},
  {currency:"SCR", name: "Seychellois Rupeename"},
  {currency:"SDG", name: "Sudanese Poundname"},
  {currency:"SEK", name: "Swedish Kronaname"},
  {currency:"SGD", name: "Singapore Dollarname"},
  {currency:"SHP", name: "Saint Helena Poundname"},
  {currency:"SLL", name: "Sierra Leonean Leonename"},
  {currency:"SOS", name: "Somali Shillingname"},
  {currency:"SRD", name: "Surinamese Dollarname"},
  {currency:"SSP", name: "South Sudanese Poundname"},
  {currency:"STD", name: "São Tomé and Príncipe Dobra (pre-2018)"},
  {currency:"STN", name: "São Tomé and Príncipe Dobraname"},
  {currency:"SVC", name: "Salvadoran Colónname"},
  {currency:"SYP", name: "Syrian Poundname"},
  {currency:"SZL", name: "Swazi Lilangeniname"},
  {currency:"THB", name: "Thai Bahtname"},
  {currency:"TJS", name: "Tajikistani Somoniname"},
  {currency:"TMT", name: "Turkmenistani Manatname"},
  {currency:"TND", name: "Tunisian Dinarname"},
  {currency:"TOP", name: "Tongan Pa'anganame"},
  {currency:"TRY", name: "Turkish Liraname"},
  {currency:"TTD", name: "Trinidad and Tobago Dollarname"},
  {currency:"TWD", name: "New Taiwan Dollarname"},
  {currency:"TZS", name: "Tanzanian Shillingname"},
  {currency:"UAH", name: "Ukrainian Hryvnianame"},
  {currency:"UGX", name: "Ugandan Shillingname"},
  {currency:"USD", name: "United States Dollarname"},
  {currency:"UYU", name: "Uruguayan Pesoname"},
  {currency:"UZS", name: "Uzbekistan Somname"},
  {currency:"VEF", name: "Venezuelan Bolívar Fuerte (Old)"},
  {currency:"VES", name: "Venezuelan Bolívar Soberanoname"},
  {currency:"VND", name: "Vietnamese Dongname"},
  {currency:"VUV", name: "Vanuatu Vatuname"},
  {currency:"WST", name: "Samoan Talaname"},
  {currency:"XAF", name: "CFA Franc BEACname"},
  {currency:"XAG", name: "Silver Ouncename"},
  {currency:"XAU", name: "Gold Ouncename"},
  {currency:"XCD", name: "East Caribbean Dollarname"},
  {currency:"XDR", name: "Special Drawing Rightsname"},
  {currency:"XOF", name: "CFA Franc BCEAOname"},
  {currency:"XPD", name: "Palladium Ouncename"},
  {currency:"XPF", name: "CFP Francname"},
  {currency:"XPT", name: "Platinum Ouncename"},
  {currency:"YER", name: "Yemeni Rialname"},
  {currency:"ZAR", name: "South African Randname"},
  {currency:"ZMW", name: "Zambian Kwachaname"},
  {currency:"ZWL", name: "Zimbabwean Dollarname "},
]