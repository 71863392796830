import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { DriverService } from '../../services';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss']
})
export class IncomeComponent implements OnInit {
  drivers: any[];
  clicked: boolean = false;
  currentDriver: any;
  loading: boolean = false;

  ColumnMode = ColumnMode;
  columns = [
    { prop: 'TripValue', name: 'TripValue' },
    { name: 'Commission', prop: 'Commission' },
    { name: 'Date', prop: 'Date' }
  ];

  constructor(private driverService: DriverService) { }

  driversReport: any[];
  tempArr = [];



  @ViewChild(DatatableComponent) table: DatatableComponent;

  ngOnInit() {
    const driversObservable = this.driverService.getDrivers();
    driversObservable.subscribe((drivers) => {
      this.drivers = drivers;
    })
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempArr.filter(function (d) {
      return d.date.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.driversReport = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  loadDriverReports(driverId: string, driverName: string) {
    // console.log("Driver", driverId);
    this.clicked = true;
    this.loading = true;
    const reportsObservable = this.driverService.getDriversReport(driverId);

    reportsObservable.subscribe(reports => {
      this.driversReport = reports;
      this.tempArr = [...reports];

      this.currentDriver = driverName;
      console.log(this.currentDriver);

      window.scrollTo(0, 0);
      this.loading = false;

    }, (error) => {
      console.log("An error occured");
    });

  }

  selected = [];
  SelectionType = SelectionType;

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  onActivate(event) {
    console.log('Activate Event', event);
  }

}
