import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable, pipe } from 'rxjs';

import { Product } from '../models/product';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class ProductService {

  // calls AngularFirestore then passes in Product model interface
  productsCollection: AngularFirestoreCollection<Product>;
  productDoc: AngularFirestoreDocument<Product>;
  orderDoc: AngularFirestoreDocument<any>;
  // plural or as an array
  products: Observable<Product[]>;
  orders: Observable<any[]>;
  categories: Observable<any[]>;
  // singular or independent instance
  product: Observable<Product>;

  categoriesCollection: AngularFirestoreCollection<any>;
  ordersCollection: AngularFirestoreCollection<any>;



  // inject dependency services in the constructor
  constructor(private afs: AngularFirestore) {
    this.productsCollection = this.afs.collection("glyyde").doc("Products").collection("KE");
    this.categoriesCollection = this.afs.collection("glyyde").doc("ProductCategories").collection("ProductCategories");
    // this.orderDoc = this.afs.collection("glyyde").doc("Orders").collection("ProductCategories");
    this.orderDoc = this.afs.collection("glyyde").doc("Orders");
  }

  getProducts(): Observable<Product[]> {

    this.products = this.productsCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as Product;
        data.id = action.payload.doc.id;
        return data;
      });
    }));

    return this.products;
  }

  getCategories(): Observable<any[]> {

    this.categories = this.categoriesCollection.snapshotChanges().pipe(map(changes => {
      return changes.map(action => {
        const data = action.payload.doc.data() as Product;
        data.id = action.payload.doc.id;
        return data;
      });
    }));

    return this.categories;
  }

  getCategoryProducts(category: string): Observable<any[]> {
    // return this.productsCollection.ref.where('Category', '==', category).get().then;
    const products = this.productsCollection
      .valueChanges()
      .pipe(
        map(products => {
          console.log(products);
          return products;
        })
      );

    return products;
  }

  getOrders(customerId) {
    this.orders = this.orderDoc.collection(customerId).snapshotChanges().pipe(
      map(changes => {
          return changes.map(action => {
              const data = action.payload.doc.data();
              // @ts-ignore
              data.id = action.payload.doc.id;
              return data;
          });
      }));

  return this.orders;


    // this.ordersCollection = this.afs.collection("glyyde").doc("Orders").collection(customerId);
    // return this.orderDoc.valueChanges();
  }

  updateOrder(order: any) {
    this.orderDoc = this.ordersCollection.doc<any>(order.id);
    this.orderDoc.update(order);
  }

  newProduct(product: Product) {
    this.productsCollection.add(product);
  }

  addCategory(category: any) {
    this.categoriesCollection.doc(category.id).set(category);
  }

  getProduct(id: string): Observable<Product> {
    return this.productsCollection.doc<Product>(id).valueChanges();
  }

  updateProduct(product: Product, id: string) {
    this.productDoc = this.afs.collection("glyyde").doc("Products").collection("KE").doc(id);
    this.productDoc.update(product);
  }

  deleteProduct(product: Product, id: string) {
    this.productDoc = this.afs.doc(id);
    this.productDoc.delete();
  }

}
