import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { Product } from '../../models/product';
import { Supplier } from '../../models/supplier';
import { SupplierService } from '../../services';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-products',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductsComponent implements OnInit {

  // define properties
  products: Product[];
  suppliers: Supplier[];
  ColumnMode = ColumnMode;
  columns = [
    { prop: 'Name', name: 'Name' },
    { name: 'Price', prop: 'Price' },
    { name: 'Quantity', prop: 'Quantity' },
    { name: 'Supplier', prop: 'OfferedBy' },
    { name: 'Uom', prop: 'Uom' }
  ];


  @ViewChild(DatatableComponent) table: DatatableComponent;
  tempArr: any[];

  // inject dependency services in the constructor
  constructor(
    private productService: ProductService,
    private supplierService: SupplierService,
    private router: Router
  ) { }

  ngOnInit() {
    this.supplierService.getSuppliers().subscribe(suppliers => {
      this.suppliers = suppliers;
    })

    this.productService.getProducts().subscribe(products => {
      this.products = products;


      this.products.forEach(product => {

        this.suppliers.filter(supplier => {
          if (supplier.ID == product.OfferedBy) {
            product.OfferedBy = supplier.FirstName;
          };
        });
      });

      console.log('Suppliers: ', this.suppliers);
      console.log('products: ', this.products)
      this.tempArr = [...products]
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempArr.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.products = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  selected = [];
  SelectionType = SelectionType;

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
    this.router.navigate([`/product/ref/${this.selected[0].id}`])
  }

}
