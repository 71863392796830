import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DriverService, TripService } from '../../services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loading: boolean = true;
  categories: any[];
  count: any;
  categoryForm: any;
  tripCountToday: any = 0;
  tripsCount: any = 0;
  cancelledTripsToday: any = 0;
  cancelledTripsCount: any = 0;
  cancelledTripsTodayCount: any = 0;
  trips: any[];
  cancelledTrips: any[];

  drivers: any[];

  driversData: any[] = [];

  driverTrips: any[] = [];

  moneyTendered: number = 0;
  commission: number = 0;

  driverPositions: any[];

  tripData: any[];


  constructor(
    private tripService: TripService,
    private driverService: DriverService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.loadMoneyToday();
    this.loadTripCount();
  }


  loadTripCount() {
    const tripsObservable = this.tripService.listAllTrips();

    tripsObservable.subscribe((trips) => {
      this.cancelledTrips = trips.filter(trip => {
        return trip.TripStatus = "Cancelled"
      });

      this.cancelledTripsCount = this.cancelledTrips.length;

      this.trips = trips.filter(trip => {
        return trip.TripValue > 0;
      });

      this.loadDriverData();

      this.tripsCount = this.trips.length;

    }, (error) => {
      console.log("error")
    });
  }

  loadMoneyToday() {
    const tripsTodayObservable = this.tripService.loadTripsToday();
    tripsTodayObservable.subscribe((trips) => {

      this.cancelledTripsToday = trips.filter(trip => {
        return trip.TripStatus = "Cancelled"
      });

      this.cancelledTripsTodayCount = this.cancelledTripsToday.length;

      this.trips = trips.filter(trip => {
        return trip.TripValue > 0;
      });

      this.tripCountToday = this.trips.length;

      for (let index = 0; index < this.trips.length; index++) {
        const trip = this.trips[index];
        this.moneyTendered += trip.TripValue
      }
      for (let index = 0; index < this.trips.length; index++) {
        const trip = this.trips[index];
        this.commission += trip.Commission;
      }

      this.loadComparativeData();


    }, (error) => {
      console.log("error")
    });
  }

  loadComparativeData() {
    this.tripData = [
      { name: "Trips Today", value: this.tripCountToday },
      { name: "Total Trips", value: this.tripsCount },
      { name: "Cancelled Today", value: this.cancelledTripsTodayCount },
      { name: "Total Cancelled", value: this.cancelledTripsCount },
      { name: "Total Trips", value: this.tripsCount }
    ];
  }

  //Load Data by drivers
  loadDriverData() {
    const driversObservable = this.driverService.listAllDrivers();

    driversObservable.subscribe((drivers) => {
      this.drivers = drivers;

      this.drivers.filter(driverData => {
        driverData.value = 1;
        driverData.name = `${driverData.FirstName} ${driverData.LastName}`;
      });

      this.trips.forEach(trip => {
        //@ts-ignore
        trip.AcceptDate = new Date(trip.AcceptDate.seconds * 1000).toLocaleString();
        this.drivers.filter(driverData => {
          if (driverData.id == trip.Driver) {
            trip.Name = `${driverData.FirstName} ${driverData.LastName} `;
            trip.Phone = driverData.Phone;
            driverData.value = driverData.value + 1;
          };
        });
      });

      // Create date from input value
      var inputDate = new Date("11/21/2011");

      // Get today's date
      var todaysDate = new Date();

      // call setHours to take the time out of the comparison
      if (inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
        // Date equals today's date
      }

      this.drivers.forEach(driver => {
        const pickedDetails = (({ name, value }) => ({ name, value }))(driver);
        this.driverTrips.push(pickedDetails)
      });

      this.loading = false;

    });

  }

  redirectToAddSuppliers(route: string) {
    this.router.navigate(['/supplier/add']);
  }

  redirectToAddDriverss(route: string) {
    this.router.navigate(['/drivers/add']);
  }

  redirectToSuppliers(route: string) {
    this.router.navigate(['/suppliers']);
  }

  redirectToDrivers(route: string) {
    this.router.navigate(['/drivers']);
  }
}