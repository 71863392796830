import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Supplier } from '../models/supplier';

@Injectable()
export class SupplierService {
  // calls AngularFirestore then passes in Supplier model interface
  suppliersCollection: AngularFirestoreCollection<Supplier>;
  supplierDoc: AngularFirestoreDocument<Supplier>;
  // plural or as an array
  suppliers: Observable<Supplier[]>;
  // singular or independent instance
  supplier: Observable<Supplier>;

  // inject dependency services in the constructor
  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth
    ) {
    
    this.suppliersCollection = this.afs.collection("glyyde").doc("Users").collection('Suppliers');
  }

  getSuppliers(): Observable<Supplier[]> {

    this.suppliers = this.suppliersCollection.snapshotChanges().pipe(
      map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as Supplier;
          data.ID = action.payload.doc.id;
          return data;
        });
      }));

    return this.suppliers;
  }

  newSupplier(supplier: Supplier) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(supplier.Email, supplier.Password)
          .then(userData => {
              resolve(userData);
              this.afs.collection('glyyde').doc('Users').collection('Suppliers').doc(userData.user.uid).set(
                  supplier
              );
          },
              err => reject(err))
  });
    this.suppliersCollection.add(supplier);
  }

  getSupplier(id: string): Observable<Supplier> {
    return this.suppliersCollection.doc<any>(id).valueChanges();
    
  }

  updateSupplier(supplier: Supplier) {
    //@ts-ignore
    this.supplierDoc = this.suppliersCollection.doc<any>(supplier.ID);
    this.supplierDoc.update(supplier);
  }

  deleteSupplier(supplier: Supplier) {
    //@ts-ignore
    this.supplierDoc = this.suppliersCollection.doc<any>(supplier.id);
    this.supplierDoc.delete();
  }

}


// 2d78722d48ca4b9ab3a483a9e9ce5e9d