import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {
  adminCollection: AngularFirestoreCollection<any>;
  adminDoc: AngularFirestoreDocument<any>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router

  ) {

  }

  login(email: string, password: string): Promise<any> {
        // return promise        
        return new Promise((resolve, reject) => {
          this.afAuth.auth.signInWithEmailAndPassword(email, password)
            .then(userData => {
              // verify admin
              resolve(userData);
            },
              err => reject(err));

        });

  }

  verifyUser(email){
    const userObservable = this.afs.collection("glyyde").doc("Users").collection("Admins").valueChanges();

    userObservable.subscribe(users => {

      const adminUser = users.filter(user => {
        return user.email == email;
      });
      console.log(adminUser);

      if (adminUser.length >= 1 && (adminUser[0] != null || adminUser[0] != undefined)) {
        this.router.navigate(['/summary']);
      } else {        
        this.router.navigate(['/create']);
        this.logout();
      }
      // this.router.navigate(['/create']);

    });
  }

  getAuth() {
    return this.afAuth.authState.pipe(map(auth => auth));
  }

  logout() {
    this.afAuth.auth.signOut();
  }

  register(email: string, password: string) {
    // return promise
    return new Promise((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(email, password)
        .then(userData => resolve(userData),
          err => reject(err))
    });
  }

}
