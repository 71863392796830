// angular
import { Component, OnInit } from '@angular/core';
// services
import { DriverService } from '../../../services/admin/driver.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.css']
})
export class DriverDetailsComponent implements OnInit {

 // define property types
 id: string;
 driver: any;

 imageSource;

 showFlag: boolean = false;
    selectedImageIndex: number = -1;
    currentIndex=1;
 

  constructor(
    private driverService: DriverService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessage: FlashMessagesService
  ) { }

  ngOnInit() {
    // get id from url 
    this.id = this.route.snapshot.params['id'];
    // loaded
    // get driver
    this.driverService.getDriver(this.id).subscribe(driver => {      
      this.driver = driver;
    });
  }

  onDeleteClick() {
    // create delete confirmation
    if (confirm('Are you sure?')) {
      // call service.method
      this.driverService.deleteDriver(this.driver);
      // offer response/feedback
      this.flashMessage.show('Driver removed', {
        cssClass: 'alert-success', timeout: 4000
      });
      // redirect
      this.router.navigate(['/summary']);
    }
  }

  showLightbox(index) {
    this.selectedImageIndex = index;
    this.showFlag = true;
}

closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
}

}
