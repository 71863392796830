import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Supplier } from '../../models/supplier';
import { MenuService } from '../../services';
import { SupplierService } from '../../services/supplier.service';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.css']
})
export class SuppliersComponent implements OnInit {

  // define properties
  countries: any[];
  suppliers: Supplier[];

  ColumnMode = ColumnMode;
  columns = [
    { prop: 'FirstName', name: 'Name' },
    { name: 'Email', prop: 'Email' },
    { name: 'Location', prop: 'LocationName' },
    { name: 'Country', prop: 'Country' },
    { name: 'Status', prop: 'Status' }];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  tempArr: any[];

  // inject dependency services in the constructor
  constructor(
    private supplierService: SupplierService,
    private flashMessage: FlashMessagesService,
    private dialogService: NbDialogService,
    private router: Router,
    private menuService: MenuService
  ) { }

  ngOnInit() {
    this.supplierService.getSuppliers().subscribe(suppliers => {
      console.log(suppliers);
      this.suppliers = suppliers;
      this.tempArr = [...suppliers]
    });
    this.countries = this.menuService.listCountries();
  }

  updateSupplier(supplier: Supplier, status: string) {
    supplier.Status = status;
    this.supplierService.updateSupplier(supplier);

    this.flashMessage.show('Supplier status updated!', {
      cssClass: 'alert-success', timeout: 4000
    });
  }

  updateFilter(event, filter) {
    console.log(filter);
    var temp;

    if (event.target.localName == "button") {
      temp = this.tempArr.filter(function (d) {
        return d.Status.toLowerCase().indexOf(filter) !== -1 || !filter;
      });
    } else {
      const val = event.target.value.toLowerCase();

      // filter our data
      temp = this.tempArr.filter(function (d) {
        // console.log(d);     
        return d[filter].toLowerCase().indexOf(val) !== -1 || !val;
      });

    }

    // update the rows
    this.suppliers = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  filterByCountry(countryName) {
    var temp;
    console.log(countryName)    

    // filter our data
    temp = this.tempArr.filter(function (d) {
      // console.log(d);     
      return d.Country.toLowerCase().indexOf(countryName.toLowerCase()) !== -1 || !countryName;
    });

    // update the rows
    this.suppliers = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;

  }

  selected = [];
  SelectionType = SelectionType;

  onSelect({ selected }, dialog) {
    console.log('Select Event', this.selected);
    this.dialogService.open(dialog, { context: selected[0], dialogClass: 'float-right' });
  }

  routeNav(route) {
    this.router.navigate([`/supplier/${route}`])
  }

}

