import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AddCarComponent,
  AddDriverComponent, CategoryComponent, CategoryProductsComponent, CommentsComponent, DriverComponent,












  DriverDetailsComponent, DriversComponent, EditDriverComponent, FareComponent,
  IncomeComponent,
  NotificationComponent,









  OrdersComponent, PassengerComponent,








  PaymentComponent, ProductsComponent, ProfileComponent,
  ReportsComponent,






  SuppliersComponent, SuppliersStatsComponent, TripActiveComponent,
  TripCompleteComponent, TripComponent,

  TripPaidComponent, WalletComponent
} from './components/';
import { AddUserComponent } from './components/auth/add-user/add-user.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AddProductComponent } from './components/products/add-products/add-product.component';
import { EditProductComponent } from './components/products/edit-product/edit-product.component';
import { ProductDetailsComponent } from './components/products/product-details/product-details.component';
import { ErrorComponent } from './components/shared/error/error.component';
import { SettingsComponent } from './components/shared/settings/settings.component';
import { SuccessComponent } from './components/shared/success/success.component';
import { AddSupplierComponent } from './components/suppliers/add-supplier/add-supplier.component';
import { EditSupplierComponent } from './components/suppliers/edit-supplier/edit-supplier.component';
import { SupplierDetailsComponent } from './components/suppliers/supplier-details/supplier-details.component';
import { AuthGuard } from './guards/auth.guard';
import { RegisterGuard } from './guards/register.guard';


const routes: Routes = [
  { path: '', redirectTo: "create", pathMatch: "full" },
  { path: 'summary', component: DashboardComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'create', component: AddUserComponent },
  { path: 'register', component: RegisterComponent, canActivate: [RegisterGuard] },
  { path: 'suppliers', component: SuppliersComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'supplier/add', redirectTo: "create"},
  { path: 'supplier/edit/:id', component: EditSupplierComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'suppliers/stats', component: SuppliersStatsComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'supplier/:id', component: SupplierDetailsComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] }, 

  { path: 'drivers', component: DriversComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'drivers/add', redirectTo: "create" },
  { path: 'driver/edit/:id', component: EditDriverComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'drivers/driver/:id', component: DriverDetailsComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'cars/add', component: AddCarComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },

  { path: 'products/add', component: AddProductComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] }, 
  { path: 'products/:country', component: ProductsComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },  
  { path: 'product/edit/:id', component: EditProductComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'product/ref/:id', component: ProductDetailsComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'product/category/:id', component: CategoryProductsComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'orders', component: OrdersComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'drivers/:action', component: DriverComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'fare', component: FareComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'cars', component: FareComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  
  { path: 'income', component: IncomeComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'notification', component: NotificationComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'passengers', component: PassengerComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'categories', component: CategoryComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'category/add', component: CategoryComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'payment', component: PaymentComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'reports', component: ReportsComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'trips', component: TripComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'comments', component: CommentsComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'trips/active', component: TripActiveComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'trips/complete', component: TripCompleteComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'trips/paid', component: TripPaidComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },
  { path: 'wallet', component: WalletComponent, runGuardsAndResolvers: 'always', canActivate: [AuthGuard] },  
  { path: 'success', component: SuccessComponent },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  exports: [RouterModule],
  imports: [
    // pass the routes array to RouterModule
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload'
    })
  ],
  providers: [AuthGuard, RegisterGuard]
})
export class AppRoutingModule { }
