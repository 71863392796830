// angular
import { Component, OnInit, ViewChild } from '@angular/core';
// services
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { SettingsService } from '../../../services/settings.service';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';

// models
import { Supplier } from '../../../models/supplier';
import { SupplierService } from '../../../services/supplier.service';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app'
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-add-supplier',
  templateUrl: './add-supplier.component.html',
  styleUrls: ['./add-supplier.component.css']
})
export class AddSupplierComponent implements OnInit {
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask
  uploadProgress: Observable<number>;
  downloadURL: Observable<any>;
  pictureUrl: string;
  uploaded: boolean = false;

  // define properties and types
  supplier: Supplier = {
    Name: '',
    Description: '',
    Email: '',
    Phone: '',
    Password: '',
  }
  public location: Location;

  @ViewChild('supplierForm', {static: false}) form: any;

  constructor(
    private flashMessage: FlashMessagesService,
    private supplierService: SupplierService,
    private router: Router,
    private settingsService: SettingsService,
    private afStorage: AngularFireStorage,
  ) { }

  ngOnInit() {
  }

  onSubmit({value, valid}: {value: Supplier, valid: boolean }) {
    console.log(value, valid);

    value.LocationName = this.location.name;
    value.LatLng = new firebase.firestore.GeoPoint(this.location.lat, this.location.lng);


    if (!valid) {
      // display an error
      this.flashMessage.show('Please fill out the form correctly', {
        cssClass: 'alert-danger', timeout: 4000
      });

      this.scrollToTop();
    } else {
      // add new supplier
      this.supplierService.newSupplier(value);
      // show message
      this.flashMessage.show('New supplier added', {
        cssClass: 'alert-success', timeout: 4000
      });
      // redirect to dash or /
      this.router.navigate(['/summary']);
    }
  }

  logLocationChange(location) {
    console.log(location);
    this.location = location;
  }

  upload(event) {
    const file = event.target.files[0];
    const filePath = "suppliers/" + this.randomString() + ".jpg";
    this.ref = this.afStorage.ref(filePath);
    this.task = this.ref.put(file);

    this.uploadProgress = this.task.percentageChanges();
    // get notified when the download URL is available
    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = this.ref.getDownloadURL();
        this.downloadURL.subscribe((data) => {
          this.pictureUrl = data;
        });
      })
    ).subscribe();

    this.uploaded = true;
  }

  randomString() {
    var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = '';
    for (var i = 10; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

}

export class Location {
  lat: number;
  lng: number;
  name?: string;
  zoom?: number;
  icon?: string;
}
