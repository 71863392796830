// angular
import { Component, OnInit } from '@angular/core';
// services
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { SettingsService } from '../../../services/settings.service';
// models
import { Product } from '../../../models/product';
import { ProductService } from '../../../services/product.service';


@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {

  id: string;
  product: Product = {
    Name: '',
    Category: '',
    Brand: '',
    Quantity: 1,
    Price: null,
    Uom: "mm",    
  }

  categories: any[];

  constructor(
    private productService: ProductService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessage: FlashMessagesService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {

    // get id from url 
    this.id = this.route.snapshot.params['id'];
    // get product
    this.productService.getProduct(this.id).subscribe(product => this.product = product);
    this.productService.getCategories().subscribe(categories => this.categories = categories);
  }

  onSubmit({value, valid}: {value: Product, valid: boolean }) {
    // submit method
    if (!valid) {
      this.flashMessage.show('Please file out the form correctly', {
        cssClass: 'alert-danger', timeout: 4000
      });
      this.scrollToTop();
    } else {
      // add id to product
      value.id = this.id
      // update product
      this.productService.updateProduct(value, this.id);
      // add alert
      this.flashMessage.show('Product updated', {
        cssClass: 'alert-success', timeout: 4000
      });
      this.router.navigate(['/product/ref/' + this.id]);
    }
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

}
