import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbActionsModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbDatepickerModule, NbDialogModule, NbInputModule, NbLayoutModule, NbListModule, NbMenuModule, NbProgressBarModule, NbSelectModule, NbSidebarModule, NbSpinnerModule, NbTabsetModule, NbThemeModule, NbToastrModule, NbTreeGridModule, NbUserModule } from '@nebular/theme';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AgGridModule } from 'ag-grid-angular';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreModule } from 'angularfire2/firestore';
import { PlacePickerModule } from 'ngx-place-picker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './/app-routing.module';
import { AppComponent } from './app.component';
import {
  AddCarComponent,
  AddDriverComponent, CategoryComponent, CategoryProductsComponent,
  CommentsComponent, DriverComponent,
  DriverDetailsComponent, DriversComponent, EditDriverComponent, FareComponent, IncomeComponent, NotificationComponent,

  OrdersComponent, PassengerComponent, PaymentComponent, ProductsComponent, ProfileComponent, ReportsComponent, SuppliersComponent,
  TripActiveComponent,
  TripCompleteComponent,
  TripComponent,
  TripPaidComponent,
  WalletComponent,
  SuppliersStatsComponent,
} from './components';
import { AddUserComponent } from './components/auth/add-user/add-user.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AddProductComponent } from './components/products/add-products/add-product.component';
import { EditProductComponent } from './components/products/edit-product/edit-product.component';
import { ProductDetailsComponent } from './components/products/product-details/product-details.component';
import { ErrorComponent } from './components/shared/error/error.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { SettingsComponent } from './components/shared/settings/settings.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { SuccessComponent } from './components/shared/success/success.component';
import { AddSupplierComponent } from './components/suppliers/add-supplier/add-supplier.component';
import { EditSupplierComponent } from './components/suppliers/edit-supplier/edit-supplier.component';
import { SupplierDetailsComponent } from './components/suppliers/supplier-details/supplier-details.component';
import { CommentsService, DriverService, FareService, MenuService, PassengerService, ProductService, SettingsService, SupplierService, TripService } from './services';
import { AuthService } from './services/auth.service';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import { NbEvaIconsModule } from '@nebular/eva-icons';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent, DashboardComponent, CommentsComponent, DriverComponent, FareComponent, IncomeComponent, NotificationComponent, PassengerComponent, PaymentComponent,
    ProductsComponent, ProfileComponent, ReportsComponent, SuppliersComponent, TripComponent, WalletComponent, SidebarComponent, AddSupplierComponent, EditSupplierComponent,
    SupplierDetailsComponent, LoginComponent, RegisterComponent, SettingsComponent, ErrorComponent, ProductDetailsComponent, AddProductComponent, EditProductComponent, CategoryComponent,
    CategoryProductsComponent, AddDriverComponent, DriverDetailsComponent, DriversComponent, EditDriverComponent, AddUserComponent, OrdersComponent, SuccessComponent, AddCarComponent,
    TripCompleteComponent, TripActiveComponent, TripPaidComponent, SuppliersStatsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NbEvaIconsModule,
    AppRoutingModule,
    NbThemeModule.forRoot(),
    NbLayoutModule,
    FlashMessagesModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase, 'clientdashboard'),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbInputModule,
    NbUserModule,
    NbListModule,
    NbCardModule,
    NbButtonModule,
    NbSelectModule,
    NbSpinnerModule,
    NbSidebarModule.forRoot(),
    NbToastrModule.forRoot(),
    NbMenuModule.forRoot(),
    AgGridModule.withComponents([]),
    NgxDatatableModule,
    NbTreeGridModule,
    NgxChartsModule,
    NbActionsModule,
    PlacePickerModule,
    NbTabsetModule,
    NbProgressBarModule, 
    NbCheckboxModule,   
    AlifeFileToBase64Module,
  ],
  providers: [SupplierService, AuthService, ProductService, ProductService, 
    CommentsService, DriverService, FareService, MenuService, PassengerService, 
    SettingsService, TripService, DriverService, AngularFirestore, AngularFireStorage],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
