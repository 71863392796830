import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { FlashMessagesService } from 'angular2-flash-messages';
import { PassengerService, ProductService, SupplierService } from '../../../services';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers-stats.component.html',
  styleUrls: ['./suppliers-stats.component.css']
})
export class SuppliersStatsComponent implements OnInit {
  orders: any;

  ColumnMode = ColumnMode;
  columns = [
    { prop: 'Name', name: 'Name' },
    { name: 'Price', prop: 'Price' },
    { name: 'Quantity', prop: 'Quantity' },
    { name: 'Total', prop: 'Total' },
  ];

  customerRows = [
    { prop: 'OrderNumber', name: 'OrderNo' },
    { name: 'Status', prop: 'Status' },
    { name: 'Total', prop: 'Total' },
    { name: 'Customer', prop: 'Customer' },
    { name: 'Supplier', prop: 'Supplier' },
    { name: 'Date', prop: 'TimeOrdered' },
  ];

  customerData: any[];
  supplierData: any[];
  currentStatus = [];

  customersChartData = [];
  productsChartData = [];

  suppliersChartData = [];

  tempArr: any[];

  ordersData: any[];

  view: any[] = [700, 300];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Supplier';
  showYAxisLabel = true;
  yAxisLabel = 'Orders';
  xAxisLabelCust = 'Customer';
  xAxisLabelProds = 'Products';


  // inject dependency services in the constructor
  constructor(
    private productService: ProductService,
    private flashMessage: FlashMessagesService,
    private dialogService: NbDialogService,
    private passengerService: PassengerService,
    private supplierService: SupplierService,
  ) { }

  ngOnInit() {
    const passengerObservable = this.passengerService.listAllOrders();
    const customersObservable = this.passengerService.listAllPassengers();
    const suppliersObservable = this.supplierService.getSuppliers();

    customersObservable.subscribe((customers) => {
      this.customerData = customers;
    })
    suppliersObservable.subscribe(suppliers => {
      this.supplierData = suppliers;
    })

    passengerObservable.subscribe((orders) => {

      this.ordersData = orders


      if (this.ordersData.length > 10) {
        this.ordersData.length = 10;
      }

      // this.customersChartData.length = this.ordersData.length;  
      // this.suppliersChartData.length = this.ordersData.length;

      this.ordersData.forEach(orderData => {
        orderData.TimeOrdered = orderData.TimeOrdered.toDate().toLocaleDateString();
        // this.productsChartData.length = orderData.Items.length;

        this.currentStatus.push(orderData.Status);

        this.customerData.filter(customer => {
          if (customer.id == orderData.CustomerID) {
            orderData.Customer = customer.Name;
            return orderData
          }
        })

        this.supplierData.filter(supplier => {
          if (supplier.ID == orderData.SupplierID) {
            orderData.Supplier = supplier.FirstName;
            return orderData;
          }
        })

        if (this.suppliersChartData.length < 1) {
          this.suppliersChartData.push({ name: orderData.Supplier, value: 1 });
        } else {
          this.suppliersChartData.forEach(supplierChartData => {
            if (supplierChartData.name == orderData.Suppiler) {
              supplierChartData.value = supplierChartData.value + 1;
            } else {
              this.suppliersChartData.push({ name: orderData.Supplier, value: 1 });
            }
          });
        }

        if (this.customersChartData.length < 1) {
          this.customersChartData.push({ name: orderData.Supplier, value: 1 });
        } else {
          this.customersChartData.forEach(customerChartData => {
            if (customerChartData.name == orderData.Customer) {
              customerChartData.value = customerChartData.value + 1;
            } else {
              this.customersChartData.push({ name: orderData.Customer, value: 1 });
            }
          });
        }

        if (this.productsChartData.length < 1) {
          this.productsChartData.push({ name: orderData.Items[0].Name, value: 1 });
          this.productsChartData = this.productsChartData;
        }
        this.productsChartData.forEach(productChartData => {
          var itemIndex = this.productsChartData.indexOf(productChartData);
          if (productChartData.name == this.productsChartData[itemIndex].name) {
            productChartData.value = productChartData.value + 1;
          } else {
            this.productsChartData.push({ name: orderData.Items[0].name, value: 1 });
          }
        });

        this.productsChartData = this.productsChartData;

        console.log(this.productsChartData);
      });


      this.tempArr = [...this.ordersData]


    }, (error) => {
      console.log("error")
    })
  }


  filterByStatus(orderStatus) {
    var temp;
    console.log(orderStatus)

    // filter our data
    temp = this.tempArr.filter(function (d) {
      // console.log(d);     
      return d.Status.toLowerCase().indexOf(orderStatus.toLowerCase()) !== -1 || !orderStatus;
    });

    // update the rows
    this.ordersData = temp;
    // Whenever the filter changes, always go back to the first page


  }


  updateOrder(order: any, status: string) {
    order.Status = status;
    this.productService.updateOrder(order);

    this.flashMessage.show('Order status updated!', {
      cssClass: 'alert-success', timeout: 4000
    });
  }

  viewOrder(order: any, status: string) {
    order.status = status;
    // this.productService.updateOrder(order);

    this.flashMessage.show('Order status updated!', {
      cssClass: 'alert-success', timeout: 4000
    });
  }

  updateFilter(event, field) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempArr.filter(function (d) {
      return d[field].toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.orders = temp;
    // Whenever the filter changes, always go back to the first page

  }

  selected = [];
  SelectionType = SelectionType;

  onSelect({ selected }, dialog) {
    console.log('Select Event', selected, this.selected);
    this.dialogService.open(dialog, { context: selected[0] });
  }

}
