// angular
import { Component, OnInit, ViewChild } from '@angular/core';
// services
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router } from '@angular/router';
import { SettingsService } from '../../../services/settings.service';

import { DriverService } from '../../../services/admin/driver.service';


@Component({
  selector: 'app-add-driver',
  templateUrl: './add-driver.component.html',
  styleUrls: ['./add-driver.component.css']
})
export class AddDriverComponent implements OnInit {

  // define properties and types
  driver: any = {
    name: '',
    description: '',
    email: '',
    phone: '',
    password: '',
  }

  @ViewChild('driverForm', {static: false}) form: any;

  constructor(
    private flashMessage: FlashMessagesService,
    private driverService: DriverService,
    private router: Router,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
  }

  onSubmit({value, valid}: {value: any, valid: boolean }) {
    console.log(value, valid);

    value.status="InActive";

    if (!valid) {
      // display an error
      this.flashMessage.show('Please fill out the form correctly', {
        cssClass: 'alert-danger', timeout: 4000
      });
      this.scrollToTop();
    } else {
      // add new driver
      this.driverService.newDriver(value);
      // show message
      this.flashMessage.show('New driver added', {
        cssClass: 'alert-success', timeout: 4000
      });
      // redirect to dash or /
      this.router.navigate(['/summary']);
    }
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

}
