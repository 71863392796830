import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { DriverService, FareService, MenuService } from '../../../services';

@Component({
  selector: 'app-add-car',
  templateUrl: './add-car.component.html',
  styleUrls: ['./add-car.component.scss']
})
export class AddCarComponent implements OnInit {
  car: any = {
    Country: '',
    Fare: '',
    Name: '',
  }
  base64Image: any;
  imageSize: any;

  base64MapImage: any;
  mapImageSize: any;
  
  countries: any[];
  currencies: any[];
  cars: any[];

  @ViewChild('carForm', { static: false }) form: any;

  constructor(
    private flashMessage: FlashMessagesService,
    private fareService: FareService,
    private router: Router,
    private menuService: MenuService,
    private driverService: DriverService
  ) { }

  ngOnInit() {    
    this.currencies = this.menuService.listCurrencies();
    this.countries = this.menuService.listCountries();
    this.driverService.listAllCars().subscribe(cars => {
      this.cars = cars;      
    });
    console.log(this.cars);
  }

  onSubmit({ value, valid }: { value: any, valid: boolean }) {
    console.log(value, valid);
    value.Image = this.base64Image;
    value.MapIcon = this.base64MapImage;

    if(value.Image == null || value.Image== undefined){
      valid = false
    }

    if(value.MapIcon == null || value.MapIcon== undefined){
      valid = false
    }

    if(this.imageSize> 1048487 || this.mapImageSize> 1048487){
      valid = false;
    }

    if (!valid) {
      // display an error
      this.flashMessage.show('Please fill out the form correctly', {
        cssClass: 'alert-danger', timeout: 4000
      });
      this.scrollToTop();
    } else {
      // add new driver
      this.fareService.addNewCar(value);
      // show message
      this.flashMessage.show('New car added', {
        cssClass: 'alert-success', timeout: 4000
      });
      // redirect to dash or /
      this.router.navigate(['/summary']);
    }
  }

  onFileChanges(event){
    console.log(event);
    this.base64Image = event[0].base64;
    this.imageSize = event[0].size;  
  }

  onMapFileChanges(event){
    console.log(event);
    this.base64MapImage = event[0].base64;
    this.mapImageSize = event[0].size;  
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }
}