import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ColumnMode, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { FlashMessagesService } from 'angular2-flash-messages';
import { PassengerService, ProductService, SupplierService } from '../../services';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
})
export class OrdersComponent implements OnInit {

  orders: any;

  ColumnMode = ColumnMode;
  columns = [
    { prop: 'Name', name: 'Name' },
    { name: 'Price', prop: 'Price' },
    { name: 'Quantity', prop: 'Quantity' },
    { name: 'Total', prop: 'Total' },
  ];

  customerRows = [
    { prop: 'OrderNumber', name: 'OrderNo' },
    { name: 'Status', prop: 'Status' },
    { name: 'Total', prop: 'Total' },
    { name: 'Customer', prop: 'Customer' },
    { name: 'Supplier', prop: 'Supplier' },
    { name: 'Date', prop: 'TimeOrdered' },
  ];

  customerData: any[];
  supplierData: any[];
  currentStatus = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  tempArr: any[];

  ordersData: any[];

  // inject dependency services in the constructor
  constructor(
    private productService: ProductService,
    private flashMessage: FlashMessagesService,
    private dialogService: NbDialogService,
    private passengerService: PassengerService,
    private supplierService: SupplierService,
  ) { }

  ngOnInit() {
    const passengerObservable = this.passengerService.listAllOrders();
    const customersObservable = this.passengerService.listAllPassengers();
    const suppliersObservable = this.supplierService.getSuppliers();

    customersObservable.subscribe((customers) => {
      this.customerData = customers;
    })
    suppliersObservable.subscribe(suppliers => {
      this.supplierData = suppliers;
    })

    passengerObservable.subscribe((orders) => {

      console.log("Raw Orders: ", orders);
      console.log("Raw Customers: ", this.customerData);
      console.log("Raw Suppliers: ", this.supplierData);

      this.ordersData = orders
      this.ordersData.filter(orderData => {
        orderData.TimeOrdered = orderData.TimeOrdered.toDate().toLocaleDateString();

        this.currentStatus.push(orderData.Status);       


        this.customerData.filter(customer => {
          if (customer.id == orderData.CustomerID) {
            orderData.Customer = customer.Name;
            return orderData
          }
        })

        this.supplierData.filter(supplier => {
          if (supplier.ID == orderData.SupplierID) {
            orderData.Supplier = supplier.FirstName;
            return orderData;
          }
        })

        //@ts-ignore
        this.currentStatus = [...new Set(this.currentStatus)];

      });
      this.tempArr = [...this.ordersData]


    }, (error) => {
      console.log("error")
    })
  }

  filterByStatus(orderStatus) {
    var temp;
    console.log(orderStatus)

    // filter our data
    temp = this.tempArr.filter(function (d) {
      // console.log(d);     
      return d.Status.toLowerCase().indexOf(orderStatus.toLowerCase()) !== -1 || !orderStatus;
    });

    // update the rows
    this.ordersData = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;

  }


  updateOrder(order: any, status: string) {
    order.Status = status;
    this.productService.updateOrder(order);

    this.flashMessage.show('Order status updated!', {
      cssClass: 'alert-success', timeout: 4000
    });
  }

  viewOrder(order: any, status: string) {
    order.status = status;
    // this.productService.updateOrder(order);

    this.flashMessage.show('Order status updated!', {
      cssClass: 'alert-success', timeout: 4000
    });
  }

  updateFilter(event, field) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempArr.filter(function (d) {
      return d[field].toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.orders = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  selected = [];
  SelectionType = SelectionType;

  onSelect({ selected }, dialog) {
    console.log('Select Event', selected, this.selected);
    this.dialogService.open(dialog, { context: selected[0] });
  }

}
