import { Component, OnInit } from '@angular/core';
import { FareService } from '../../services';
import { NbToastrService, NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-fare',
  templateUrl: './fare.component.html',
  styleUrls: ['./fare.component.scss']
})
export class FareComponent implements OnInit {
  fareTypes: any[];
  USFareTypes: any[];
  individualFares: any;
  title: string;

  loaded: boolean = false;

  constructor(
    private fareService: FareService,
    private toastService: NbToastrService,
    private dialogService: NbDialogService,
  ) { }

  ngOnInit() {
    this.loadFares();

  }

  loadFares() {
    const fareObservable = this.fareService.listAllFares();
    fareObservable.subscribe((fares) => {
      this.fareTypes = fares;
    }, error => {
      this.loaded= false;
      location.reload();
    })

    const USFareObservable = this.fareService.listUSFares();
    USFareObservable.subscribe((fares) => {
      this.USFareTypes = fares;
      this.loaded= true;
    }, error => {
      this.loaded= false;
      location.reload();
    })
  }

  listFareType(name: string, country, dialog: any){    
    this.title = name;
    console.log("Data: ", this.title );
      this.individualFares = this.fareTypes.filter(fare => {
        return fare.id == name;
      });

      console.log("Data: ", this.individualFares[0] );
      this.dialogService.open(dialog, { context: {data: this.individualFares[0], country: country }, dialogClass: 'float-right' });
  }

  modify(fare, country: string, amount){
    fare.Fare = amount
    const fareObservable = this.fareService.updateFare(fare, country);
    fareObservable.subscribe(success => {
      this.toastService.success("Fare updated successfully", "success");
      this.loadFares();
    }, error => {
      this.toastService.danger("Fare failed to updated successfully", "Error");
    }) 
  }

}
