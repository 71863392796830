// angular
import { Component, OnInit } from '@angular/core';
// services
import { DriverService } from '../../../services/admin/driver.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { SettingsService } from '../../../services/settings.service';


@Component({
  selector: 'app-edit-driver',
  templateUrl: './edit-driver.component.html',
  styleUrls: ['./edit-driver.component.css']
})
export class EditDriverComponent implements OnInit {

  id: string;
  driver: any = {
    name: '',
    description: '',
    email: '',
    phone: '',
  }

  constructor(
    private driverService: DriverService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessage: FlashMessagesService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {

    // get id from url 
    this.id = this.route.snapshot.params['id'];
    // get driver
    this.driverService.getDriver(this.id).subscribe(driver => this.driver = driver);
  }

  onSubmit({value, valid}: {value: any, valid: boolean }) {
    // submit method
    if (!valid) {
      this.flashMessage.show('Please file out the form correctly', {
        cssClass: 'alert-danger', timeout: 4000
      });
      this.scrollToTop();
    } else {
      // add id to driver
      value.id = this.id
      // update driver
      this.driverService.updateDriver(value);
      // add alert
      this.flashMessage.show('Driver updated', {
        cssClass: 'alert-success', timeout: 4000
      });
      this.router.navigate(['/driver/' + this.id]);
    }
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

}
