import { Injectable } from '@angular/core'
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Driver } from '../../interfaces/driver';

@Injectable({
    providedIn: 'root'
})
export class DriverService {
    getDriversReport(driverId: string) {
        this.driversCollection = this.afs.collection('glyyde').doc('DriversAccount').collection(driverId);
        return this.driversCollection.snapshotChanges().pipe(
            map(drivers => drivers.map(driver => {
                const data = driver.payload.doc.data();
                const id = driver.payload.doc.id;
                return { id, ...data };
            }))
        );
    }
    // calls AngularFirestore then passes in Driver model interface
    private driversCollection: AngularFirestoreCollection<Driver[]>;
    private carsCollection: AngularFirestoreCollection<Driver[]>;
    private balanceDocument: AngularFirestoreDocument
    driverDoc: AngularFirestoreDocument<any>;
    // plural or as an array
    drivers: Observable<any[]>;
    // singular or independent instance
    driver: Observable<any>;

    // inject dependency services in the constructor
    constructor(
        private afs: AngularFirestore,
        private afAuth: AngularFireAuth
    ) {

        this.driversCollection = this.afs.collection("glyyde").doc("Users").collection('Drivers');
        this.carsCollection = this.afs.collection("glyyde").doc("Fare").collection('KE');
    }

    listAllDrivers(): Observable<any[]> {
        this.driversCollection = this.afs.collection('glyyde').doc('Users').collection('Driver');
        return this.driversCollection.snapshotChanges().pipe(
            map(drivers => drivers.map(driver => {
                const data = driver.payload.doc.data();
                const id = driver.payload.doc.id;
                return { id, ...data };
            }))
        );
    }

    listAllCars(): Observable<any[]> {
        this.carsCollection = this.afs.collection('glyyde').doc('Fare').collection('KE');
        return this.carsCollection.snapshotChanges().pipe(
            map(cars => cars.map(car => {
                const data = car.payload.doc.data();
                const id = car.payload.doc.id;
                return { id, ...data };
            }))
        );
    }

    loadDriverPositions(): Observable<any[]> {
        this.driversCollection = this.afs.collection('glyyde').doc('DriversAvailable').collection('DriversAvailable');
        return this.driversCollection.snapshotChanges().pipe(
            map(drivers => drivers.map(driver => {
                const data = driver.payload.doc.data();
                const id = driver.payload.doc.id;
                return { id, ...data };
            }))
        );
    }

    loadDriverBalance(driverId: string): Observable<any> {
        this.balanceDocument = this.afs.collection('glyyde').doc('DriversAccount').collection(driverId).doc('Balance');
        return this.balanceDocument.valueChanges();

    }

    updateDriverBalance(driverId: string, balance) {
        this.balanceDocument = this.afs.collection('glyyde').doc('DriversAccount').collection(driverId).doc('Balance');
        this.balanceDocument.update(balance);
    }

    addDriver(driver: any) {
        return new Promise((resolve, reject) => {
            this.afAuth.auth.createUserWithEmailAndPassword(driver.Email, driver.Password)
                .then(userData => {
                    resolve(userData);
                    this.afs.collection('glyyde').doc('Users').collection('Driver').doc(userData.user.uid).set(
                        driver
                    );
                },
                    err => reject(err))
        });

    }

    getDrivers(): Observable<any[]> {
        this.drivers = this.driversCollection.snapshotChanges().pipe(
            map(changes => {
                return changes.map(action => {
                    const data = action.payload.doc.data();
                    // @ts-ignore
                    data.id = action.payload.doc.id;
                    return data;
                });
            }));

        return this.drivers;
    }

    newDriver(driver: any) {
        return new Promise((resolve, reject) => {
            this.afAuth.auth.createUserWithEmailAndPassword(driver.Email, driver.Password)
                .then(userData => {
                    resolve(userData);
                    this.afs.collection('glyyde').doc('Users').collection('Drivers').doc(userData.user.uid).set(
                        driver
                    );

                },
                    err => reject(err))
        });
    }

    getDriver(id: string): Observable<any> {
        return this.driversCollection.doc<any>(id).valueChanges();
    }

    updateDriver(driver: any) {
        // @ts-ignore
        this.driverDoc = this.driversCollection.doc<any>(driver.id);
        this.driverDoc.update(driver);
    }

    deleteDriver(driver: any) {
        // @ts-ignore
        this.driverDoc = this.driversCollection.doc<any>(driver.id);
        this.driverDoc.delete();
    }
}
