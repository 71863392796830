import { Injectable } from '@angular/core'
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FareService {
    private fareCollection: AngularFirestoreCollection<any[]>;
    private fareDocument: AngularFirestoreDocument<any>;

    constructor(private afs: AngularFirestore) {

    }
    listAllFares(): Observable<any[]> {
        this.fareCollection = this.afs.collection('glyyde').doc('Fare').collection('KE');
        return this.fareCollection.snapshotChanges().pipe(
            map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return { id, ...data };
            }))
        );
        // return this.fareCollection.valueChanges();
    }

    listUSFares(): Observable<any[]> {
        this.fareCollection = this.afs.collection('glyyde').doc('Fare').collection('US');
        return this.fareCollection.snapshotChanges().pipe(
            map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return { id, ...data };
            }))
        );
        // return this.fareCollection.valueChanges();
    }

    updateFare(documentName, country: string): Observable<any> {
        let fareDocument = this.afs.collection('glyyde').doc('Fare').collection(country).doc(documentName.id);
        fareDocument.update(documentName);

        return fareDocument.valueChanges();
    }

    addNewCar(carType: any) {
        this.afs.collection('glyyde').doc('Fare').collection(carType.Country).doc(carType.Name).set(carType);
    }
}
